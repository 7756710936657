@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package harutirositekmp.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.DrawableResource

private object CommonMainDrawable0 {
  public val BrassBandPhoto: DrawableResource by 
      lazy { init_BrassBandPhoto() }

  public val GeneralCompetitionTournament: DrawableResource by 
      lazy { init_GeneralCompetitionTournament() }

  public val SystemInfomationWeb: DrawableResource by 
      lazy { init_SystemInfomationWeb() }

  public val Toyokawa_te: DrawableResource by 
      lazy { init_Toyokawa_te() }

  public val TrainAlert: DrawableResource by 
      lazy { init_TrainAlert() }

  public val ait: DrawableResource by 
      lazy { init_ait() }

  public val clips: DrawableResource by 
      lazy { init_clips() }

  public val compose_multiplatform: DrawableResource by 
      lazy { init_compose_multiplatform() }

  public val my_icon: DrawableResource by 
      lazy { init_my_icon() }

  public val portfolio: DrawableResource by 
      lazy { init_portfolio() }

  public val potimane: DrawableResource by 
      lazy { init_potimane() }

  public val supporterz: DrawableResource by 
      lazy { init_supporterz() }
}

public val Res.drawable.BrassBandPhoto: DrawableResource
  get() = CommonMainDrawable0.BrassBandPhoto

private fun init_BrassBandPhoto(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:BrassBandPhoto",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/BrassBandPhoto.jpg", -1, -1),
    )
)

public val Res.drawable.GeneralCompetitionTournament: DrawableResource
  get() = CommonMainDrawable0.GeneralCompetitionTournament

private fun init_GeneralCompetitionTournament(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:GeneralCompetitionTournament",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/GeneralCompetitionTournament.jpg", -1, -1),
    )
)

public val Res.drawable.SystemInfomationWeb: DrawableResource
  get() = CommonMainDrawable0.SystemInfomationWeb

private fun init_SystemInfomationWeb(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:SystemInfomationWeb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/SystemInfomationWeb.png", -1, -1),
    )
)

public val Res.drawable.Toyokawa_te: DrawableResource
  get() = CommonMainDrawable0.Toyokawa_te

private fun init_Toyokawa_te(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:Toyokawa_te",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/Toyokawa-te.jpg", -1, -1),
    )
)

public val Res.drawable.TrainAlert: DrawableResource
  get() = CommonMainDrawable0.TrainAlert

private fun init_TrainAlert(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:TrainAlert",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/TrainAlert.png", -1, -1),
    )
)

public val Res.drawable.ait: DrawableResource
  get() = CommonMainDrawable0.ait

private fun init_ait(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ait",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/ait.jpg", -1, -1),
    )
)

public val Res.drawable.clips: DrawableResource
  get() = CommonMainDrawable0.clips

private fun init_clips(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:clips",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/clips.png", -1, -1),
    )
)

public val Res.drawable.compose_multiplatform: DrawableResource
  get() = CommonMainDrawable0.compose_multiplatform

private fun init_compose_multiplatform(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:compose_multiplatform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/compose-multiplatform.xml", -1, -1),
    )
)

public val Res.drawable.my_icon: DrawableResource
  get() = CommonMainDrawable0.my_icon

private fun init_my_icon(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:my_icon",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/my_icon.webp", -1, -1),
    )
)

public val Res.drawable.portfolio: DrawableResource
  get() = CommonMainDrawable0.portfolio

private fun init_portfolio(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:portfolio",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/portfolio", -1, -1),
    )
)

public val Res.drawable.potimane: DrawableResource
  get() = CommonMainDrawable0.potimane

private fun init_potimane(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:potimane",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/potimane.png", -1, -1),
    )
)

public val Res.drawable.supporterz: DrawableResource
  get() = CommonMainDrawable0.supporterz

private fun init_supporterz(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:supporterz",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/drawable/supporterz.png", -1, -1),
    )
)
