@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package harutirositekmp.composeapp.generated.resources

import kotlin.OptIn
import org.jetbrains.compose.resources.FontResource

private object CommonMainFont0 {
  public val kosugi_maru: FontResource by 
      lazy { init_kosugi_maru() }
}

public val Res.font.kosugi_maru: FontResource
  get() = CommonMainFont0.kosugi_maru

private fun init_kosugi_maru(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:kosugi_maru",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/harutirositekmp.composeapp.generated.resources/font/kosugi_maru.ttf", -1, -1),
    )
)
